<template>
    <div>
        <h3>Service History  of {{  $route.params.uid }}</h3>
        <el-table :data="items" border v-loading="loading" class="text-center">
            <el-table-column prop="index" type="index" label="Sl#"  width="150px" align="center"/>
            <el-table-column prop="service_date" label="Service Date" align="center"/>
            <el-table-column prop="product_type" label="Product Type" align="center"/>
            <el-table-column prop="size" label="Size" align="center"/>
            <el-table-column prop="quantity" label="Quantity" align="center"/>
            <el-table-column prop="remarks" label="Remarks" align="center"/>
            <el-table-column prop="created_at" label="Created At" align="center"/>
        </el-table>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'ServiceHistory',
    data() {
        return {
            items: [],
            loading: false,
        }
    },
    created() {
        this.getServiceHistory();
    },
    methods: {
        getServiceHistory() {
            axios.get(`/api/v2/invoices/${ this.$route.params.uid }/services`)
            .then((res) => {
            if (res.data.status_code == 500) {
                this.$notify({
                title: 'Failed',
                message: res.data.message,
                type: 'danger',
                duration: 2000,
                });
            }
            this.items = res.data.services;
            });
        },
    },

}
</script>